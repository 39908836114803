<template>
    <div class="TemplateView">
        <div id="subdiv" v-if="filetemplate !=null" >
            <div v-if="filetemplate.templatecontent !=null && filetemplate.templatecontent!=''">
                <div id="printdiv">
                    <Button type="warning" @click="previewcontent">打印预览</Button>
                    <Button type="primary" @click="printcontent" style="margin-left: 15px;">直接打印</Button>
                </div>
                <div id="contentdiv"  v-html="filetemplate.templatecontent"></div>
            </div>
            <div v-else ><span style="color: red;font-size: 20px;">模板内容还没有编写喔!</span></div>
        </div>
        <div v-else>正在读取模板内容...</div>
    </div>

  </template>
  
  <script>
import {getPrinters,public_print,public_print_with_dir,public_preview_with_dir} from '@/zsjs/zskjprinter.js'
  export default {
    name: "TemplateView",
    props: {
    },
    data(){
        return {
            templateid:window.atob(this.$route.query.templateid),
            userCategory:this._self.$root.$store.state.LogonInfo.userCategory,
            filetemplate:null
        }
    },
    mounted(){
      this.querytemplate()
    },
    methods:{
        querytemplate(){
            let posturl
            if(this.userCategory == 'superadmin'){
                posturl = "/api/checkdept/template/public/query"
            }
            else{
                posturl = "/api/checkdept/template/private/query"
            }

            let postdata={
                templateid: this.templateid,
                pageNum: 0,
                pageSize: 10
            }

            this.$Loading.start();
            this.$axios({
                method:'post',
                url:posturl,
                data:postdata,
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
                }).then(function(res){
                if(res.data.resultCode ==0){
                    this.$Loading.finish();
                    console.log(res)
                    if(res.data.data.totalnumber == 0){
                        this.$Loading.error();
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                    else{
                        this.filetemplate = res.data.data.data[0]
                        console.log(this.filetemplate)
                    }
                }
                else{
                    this.$Loading.error();
                    this.$Message.error({content:res.data.msg, duration:3})
                }
                }.bind(this));   
        },
        previewcontent(){
            public_preview_with_dir("contentdiv",1,this.filetemplate.direction);
        },
        printcontent(){
            public_print_with_dir("contentdiv",1,this.filetemplate.direction);
        }
    }
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
      .TemplateView {
        margin:20px auto;
        padding: 5%;
      }
    #subdiv {
        margin: 0 auto;
    }
    #printdiv {
        margin:2px auto;
        width:1100px;
        text-align: right;
    }
    #contentdiv {
        margin:0 auto;
        width:1100px;
        text-align: left;
        padding: 5px 10px;
        border: 1px solid lightgray;
    }

    #contentdiv p{
        text-align: left;
    }
  </style>  